
.subscription-page {
  .payment-methods {
    .payment-method {
      padding: 10px 0;
      .card-brand {
        font-size: 16px;
        font-weight: 500;
        color: #2c2e2f;
        text-transform: uppercase;
      }
      .card-bumber,
      .card-expiration {
        font-weight: 500;
        color: #666;
      }
    }
  }
  .payment-methods .payment-method .actions {
    margin-top: 10px;
  }
  .payment-methods .payment-method .actions a[disabled] {
    opacity: 0.6;
  }
  .logout-button {
    max-width: 200px;
    position: absolute;
    top: 50px;
    right: 50px;
  }
}
